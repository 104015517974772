import * as React from "react";

import Topic from "../components/topic";

// markup
const ServicePage = () => {
  const post = {
    title: 'Hire Dedicated Product Developers | Offshore Product Development Company in India',
    metaDescription: '',
    h1: 'Hire Dedicated Product Developers',
    h1Subtext1: 'Quickly build a new product and bring it to the market with MatteCurve Team.',
    h1Subtext2: 'MatteCurve is the best Product development company in India. We are a JavaScript company and have dedicated Product developers working remotely. We work with fortune 500 companies and startups to build end-to-end solutions based on the JavaScript ecosystem. Share your requirements and hire Product developers within 7 days.',
    aboutSection: {
        title: 'About Product Development',
        subTitle: 'A complete process of delivering a new product or improving an existing one for customers.',
        paragraph1: `A clear product development process gives companies a way to explore new product ideas and learn what customers want in the early stages of conceptualization. The objective is to ensure that the new or enhanced product satisfies a real customer need and helps the company reach business goals.`,
        paragraph2: "The first stage in any product development process is to identify and analyze the business opportunity — this can be how you serve external customers or build internal products for the organization. Before a concept moves to implementation, you must determine if and how it supports overall business objectives. A feasibility study, for example, can help teams determine whether a concept has the potential to be successful before a full go-to-market investment is made.",
        paragraph3: `Product developers are skilled in specific technologies with domain knowledge. 
        Product developers have a mindset of doing research, asking product relevant questions, and solve those problems using their skills.`,
        keyPoints: [
            "Building a marketing fit product in not easy like building a simple mobile or web app.",
            "Agile product development helps to get early feedback on the development.",
            "Jira or similar softwares are used to track progress and document all the requirements.",
            "Automated deployments help a lot in product development as it reduces time spent on releases.",
            "Product manager is a key person in product development.",
            "Product development should be started with clear vision only.",
            "A product development road map helps to understand what we are going to develop next and it creates more impact.",
            "Product development also includes developers, designers, quality testers, content writers, SEO specialists, Digital marketing specialists, data analysts, scrum master, etc.",
            "In product development we focus on solving a problem and multiple iterations are done to improve performance or user interfaces."
        ],
    },
    statsSection: {
        paragraph: "There is a new startups every week with some funding news. Most of the startups are built around a product. Hire Dedicated Product Developers in India from MatteCurve to grow by 4X.",
    },
    benefitsSection: {
        title: "Benefits of Hiring Dedicated Product Developers",
        keyPoints: [
            "Improve Project Outcomes",
            "Cost-effective",
            "No investment On Hardware/Software Requirement",
            "Get Access to Skilled Developers",
            "Agile Approach",
            "Collaborative Engagement",
            "Painless Project Management",
            "Greater focus on business"
        ],
        image: "https://images.unsplash.com/photo-1603404235009-71c1f80e31fc?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1000&q=80",
    },
    ctaTexts: [
        "Hire dedicated Product Developers?",
        "Hire dedicated Product Developers from MatteCurve?",
    ],
    why: {
        title: "Why Choose MatteCurve for Product Development?",
        paragraph: "MatteCurve has been recognized as one of the leading web development companies in building high-performance Product apps from India. MatteCurve offers you deep expertise in Product web development and Product app development, delivered by a team of highly-skilled Product Developers with unmatched expertise in building progressive and dynamic web apps using Product.",
    }
  };


  return (
    <Topic post={post}></Topic>
  )
}

export default ServicePage;
